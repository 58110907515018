export const ctrlValues = {
  min: {
    width: 300,
    height: 300,
    yaw: -60,
    pitch: 0,
    distance: 0,
    targetX: -5,
    targetY: -5,
    targetZ: -5,
  },
  max: {
    width: 900,
    height: 900,
    yaw: 60,
    pitch: 60,
    distance: 10,
    targetX: 5,
    targetY: 5,
    targetZ: 5,
  },
  step: {
    width: 1,
    height: 1,
    yaw: 0.1,
    pitch: 0.1,
    distance: 0.01,
    targetX: 0.01,
    targetY: 0.01,
    targetZ: 0.01,
  }
};



