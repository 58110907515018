export const BlindIdMap = {

    "8523":"4001_16",
    "8524":"4001_25",
    "8525":"4001_50",
    "8526":"4003_25",
    "8527":"4004_25",
    "8528":"4004_50",
    "8529":"4005_25",
    "8530":"4005_50",
    "8531":"4006_16",
    "8532":"4006_25",
    "8533":"4006_50",
    "8534":"4007_16",
    "8535":"4007_25",
    "8536":"4007_50",
    "8537":"4008_16",
    "8538":"4008_25",
    "8539":"4009_25",
    "8540":"4010_16",
    "8541":"4010_25",
    "8542":"4010_50",
    "8543":"4011_16",
    "8544":"4011_25",
    "8545":"4011_50",
    "8546":"4012_25",
    "8547":"4013_25",
    "8548":"4014_25",
    "8549":"4014_50",
    "8550":"4015_16",
    "8551":"4015_25",
    "8552":"4015_50",
    "8553":"4016_16",
    "8554":"4016_25",
    "8555":"4016_50",
    "8556":"4017_25",
    "8557":"4017_50",
    "8558":"4018_16",
    "8559":"4018_25",
    "8560":"4018_50",
    "8561":"4019_25",
    "8562":"4019_50",
    "8563":"4020_25",
    "8564":"4020_50",
    "8565":"4021_25",
    "8566":"4021_50",
    "8567":"4022_16",
    "8568":"4022_25",
    "8569":"4022_50",
    "8570":"4023_16",
    "8571":"4023_25",
    "8572":"4024_25",
    "8573":"4025_25",
    "8574":"4025_50",
    "8575":"4026_16",
    "8576":"4026_25",
    "8577":"4026_50",
    "8578":"4027_25",
    "8579":"4027_50",
    "8580":"4028_16",
    "8581":"4028_25",
    "8582":"4028_50",
    "8583":"4029_25",
    "8584":"4029_50",
    "8585":"4031_25",
    "8586":"4031_50",
    "8587":"4032_25",
    "8588":"4032_50",
    "8589":"4033_25",
    "8590":"4033_50",
    "8591":"4034_25",
    "8592":"4034_50",
    "8593":"4035_25",
    "8594":"4035_50",
    "8595":"4036_16",
    "8596":"4036_25",
    "8597":"4036_50",
    "8598":"4037_25",
    "8599":"4037_50",
    "8600":"4038_16",
    "8601":"4038_25",
    "8602":"4038_50",
    "8603":"4039_25",
    "8604":"4039_50",
    "8605":"4040_25",
    "8606":"4040_50",
    "8607":"4041_25",
    "8608":"4041_50",
    "8609":"4042_16",
    "8610":"4042_25",
    "8611":"4043_25",
    "8612":"4044_25",
    "8613":"4045_50",
    "8614":"4046_25",
    "8615":"4046_50",
    "8616":"4047_25",
    "8617":"4047_50",
    "8618":"4048_16",
    "8619":"4048_25",
    "8620":"4048_50",
    "8621":"4049_25",
    "8622":"4049_50",
    "8623":"4050_16",
    "8624":"4050_25",
    "8625":"4050_50",
    "8626":"4051_25",
    "8627":"4051_50",
    "8628":"4052_25",
    "8629":"4052_50",
    "8630":"4053_16",
    "8631":"4053_25",
    "8632":"4053_50",
    "8633":"4054_25",
    "8634":"4054_50",
    "8635":"4055_25",
    "8636":"4056_16",
    "8637":"4056_25",
    "8638":"4056_50",
    "8639":"4057_25",
    "8640":"4057_50",
    "8641":"4059_25",
    "8642":"4059_50",
    "8643":"4060_25",
    "8644":"4060_50",
    "8645":"4061_25",
    "8646":"4061_50",
    "8647":"4062_25",
    "8648":"4063_25",
    "8649":"4063_50",
    "8650":"4064_16",
    "8651":"4064_25",
    "8652":"4064_50",
    "8653":"4065_25",
    "8654":"4066_25",
    "8655":"4067_25",
    "8656":"4068_25",
    "8657":"4069_25",
    "8658":"4070_25",
    "8659":"4071_25",
    "8660":"4072_25",
    "8661":"4073_25",
    "8662":"4074_25",
    "8663":"4075_50",
    "8664":"4077_25",
    "8665":"4077_50",
    "8666":"4078_50",
    "8667":"4079_16",
    "8668":"4079_25",
    "8669":"4079_50",
    "8670":"4080_25",
    "8671":"4080_50",
    "8672":"4081_16",
    "8673":"4081_25",
    "8674":"4081_50",
    "8675":"4082_50",
    "8676":"4083_25",
    "8677":"4084_16",
    "8678":"4084_25",
    "8679":"4085_25",
    "8680":"4086_25",
    "8681":"4089_25",
    "8682":"4089_50",
    "8683":"4090_25",
    "8684":"4090_50",
    "8685":"4093_25",
    "8686":"4093_50",
    "8687":"4094_25",
    "8688":"4094_50",
    "8689":"4095_25",
    "8690":"4095_50",
    "8691":"4096_25",
    "8692":"4096_50",
    "8693":"4097_25",
    "8694":"4097_50",
    "8695":"4098_25",
    "8696":"4100_25",
    "8697":"4101_25",
    "8698":"4103_25",
    "8699":"4103_50",
    "8700":"4104_25",
    "8701":"4105_25",
    "8702":"4105_50",
    "8703":"4106_25",
    "8704":"4106_50",
    "8705":"4107_25",
    "8706":"4107_50",
    "8707":"4108_25",
    "8708":"4109_25",
    "8709":"4110_25",
    "8710":"4111_25",
    "8711":"4112_25",
    "8712":"4113_25",
    "8713":"4114_16",
    "8714":"4114_25",
    "8715":"4115_25",
    "8716":"4116_25",
    "8717":"4116_50",
    "8718":"4117_25",
    "8719":"4118_25",
    "8720":"4119_25",
    "8721":"4120_25",
    "8722":"4121_25",
    "8723":"4122_25",
    "8724":"4123_25",
    "8725":"4123_50",
    "8726":"4124_25",
    "8727":"4125_16",
    "8728":"4125_25",
    "8729":"4125_50",
    "8730":"4126_25",
    "8731":"4126_50",
    "8732":"4127_25",
    "8733":"4128_25",
    "8734":"4128_50",
    "8735":"4129_25",
    "8736":"4129_50",
    "8737":"4130_25",
    "8738":"4130_50",
    "8739":"4131_25",
    "8740":"4132_25",
    "8741":"4133_25",
    "8742":"4134_25",
    "8743":"4134_50",
    "8744":"4138_25",
    "8745":"4139_25",
    "8746":"4140_25",
    "8747":"4141_25",
    "8748":"4142_25",
    "8749":"4143_25"
}
