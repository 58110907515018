<template>

  <SceneMaker v-if="!isProd && mode==='maker'" />
  <SceneViewer v-else />
<!--  <BugReport />-->
</template>

<script>
import SceneViewer from "./components/SceneViewer.vue";
import SceneMaker from "./components/SceneMaker.vue";
// import BugReport from "./components/BugReport.vue";
import {ref} from "vue";

export default {
  name: "App",
  components: {
    SceneViewer,
    SceneMaker,
    // BugReport
  },
  setup(props) {
    const defaultMode = 'viewer';
    let queryParams = new URLSearchParams(window.location.search);
    let mode = ref(queryParams.has('mode') ? queryParams.get('mode') : defaultMode);
    const isProd = process.env.NODE_ENV==='production';
    return {
      mode,
      isProd
    };
  },


}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: lightgray;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*height:100vh;*/
  /*width:100vw;*/
  /*overflow:hidden;*/
  display:flex;
  margin:auto;
  justify-items: center;
  align-content: center;
  align-items:center;

}
</style>
