export const BasicPlisseeIdMap = {
    "8856":"37000",
    "8857":"37001",
    "8858":"37002",
    "8859":"37003",
    "8860":"37004",
    "8861":"37005",
    "8862":"37006",
    "8863":"37007",
    "8864":"37008",
    "8865":"37009",
    "8866":"37010",
    "8867":"37011",
    "8868":"37012",
    "8869":"37013",
    "8870":"37014",
    "8871":"37100",
    "8872":"37101",
    "8873":"37102",
    "8874":"37103",
    "8875":"37104",
    "8876":"37105",
    "8877":"37106",
    "8878":"37107",
    "8879":"37108",
    "8880":"37109",
    "8881":"37110",
    "8882":"37111",
    "8883":"37112",
    "8884":"37113",
    "8885":"37114",
    "8886":"37115",
    "8887":"37116",
    "8888":"37117",
    "8889":"37200",
    "8890":"37201",
    "8891":"37202",
    "8892":"37203",
    "8893":"37300",
    "8894":"37301",
    "8750":"30000",
    "8751":"30100",
    "8752":"30101",
    "8753":"30102",
    "8754":"30103",
    "8755":"30104",
    "8756":"30105",
    "8757":"30106",
    "8758":"30107",
    "8759":"30108",
    "8760":"30109",
    "8761":"30110",
    "8762":"30111",
    "8763":"30112",
    "8764":"30113",
    "8765":"30114",
    "8766":"30200",
    "8767":"30201",
    "8768":"30202",
    "8769":"30203",
    "8770":"30204",
    "8771":"30205",
    "8772":"30206",
    "8773":"30207",
    "8774":"30208",
    "8775":"30209",
    "8776":"30210",
    "8777":"30211",
    "8778":"30300",
    "8779":"30301",
    "8780":"30302",
    "8781":"30303",
    "8782":"30304",
    "8783":"30305",
    "8784":"30306",
    "8785":"30307",
    "8786":"30308",
    "8787":"30309",
    "8788":"30310",
    "8789":"30311",
    "8790":"30312",
    "8791":"30400",
    "8792":"30401",
    "8793":"30402",
    "8794":"30403",
    "8795":"30404",
    "8796":"30405",
    "8797":"30406",
    "8798":"30407",
    "8799":"30408",
    "8800":"30409",
    "8801":"30410",
    "8802":"30411",
    "8803":"30412",
    "8804":"30500",
    "8805":"30600",
    "8806":"30601",
    "8807":"30602",
    "8808":"30603",
    "8809":"30604",
    "8810":"30605",
    "8811":"30606",
    "8812":"30607",
    "8813":"30608",
    "8814":"30609",
    "8815":"30610",
    "8816":"30611",
    "8817":"30612",
    "8818":"30613",
    "8819":"30614",
    "8820":"30700",
    "8821":"30701",
    "8822":"30702",
    "8823":"30703",
    "8824":"30800",
    "8825":"30801",
    "8826":"30802",
    "8827":"30803",
    "8828":"30804",
    "8829":"30900",
    "8830":"30901",
    "8831":"30902",
    "8832":"30903",
    "8833":"30904",
    "8834":"30905",
    "8835":"30906",
    "8836":"30907",
    "8837":"30908",
    "8838":"30909",
    "8839":"31000",
    "8840":"31001",
    "8841":"31002",
    "8842":"31003",
    "8843":"31004",
    "8844":"31005",
    "8845":"31006",
    "8846":"31100",
    "8847":"31101",
    "8848":"31102",
    "8849":"31103",
    "8850":"31104",
    "8851":"31105",
    "8852":"31106",
    "8853":"31107",
    "8854":"31108",
    "8855":"31109",

}
