
export const BlindIdMap2 = {

    "8952":"5010",
    "8953":"5011",
    "8954":"5012",
    "8955":"5013",
    "8956":"5014",
    "8957":"5015",
    "8958":"5016",
    "8959":"5019",
    "8960":"5020",
    "8961":"5021",
    "8962":"5022",
    "8963":"5050",
    "8964":"5052",
    "8965":"5054",
    "8966":"5055",
    "8967":"5056",
    "8968":"5010",
    "8969":"5011",
    "8970":"5012",
    "8971":"5013",
    "8972":"5014",
    "8973":"5015",
    "8974":"5016",
    "8975":"5019",
    "8976":"5020",
    "8977":"5021",
    "8978":"5022",
    "8979":"5050",
    "8980":"5051",
    "8981":"5052",
    "8982":"5053",
    "8983":"5054",
    "8984":"5055",
    "8985":"5056",
    "8986":"5060",
    "8987":"5079",
    "8988":"5080",
    "8989":"5081",
    "8990":"5082",
    "8991":"5083",
    "8992":"5086",
    "8993":"5087",
    "8994":"5088",
    "8995":"5089",
    "8996":"5091",
    "8997":"5092",
    "8998":"5093",
    "8999":"5094",
    "9000":"5095",
    "9001":"5096",
    "9002":"5097",
    "9003":"5098",
    "9004":"5040",
    "9005":"5041",
    "9006":"5042",
    "9007":"5045",
    "9008":"5046",
    "9009":"5047",
    "9010":"5061",
    "9011":"5062",
    "9012":"5063",
    "9013":"5064",
    "9014":"5065",
    "9015":"5066",
    "9016":"5067",
    "9017":"5068",
    "9018":"5040",
    "9019":"5041",
    "9020":"5042",
    "9021":"5045",
    "9022":"5046",
    "9023":"5047",
    "9024":"5061",
    "9025":"5062",
    "9026":"5063",
    "9027":"5064",
    "9028":"5065",
    "9029":"5066",
    "9030":"5067",
    "9031":"5068"
}
