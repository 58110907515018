export const defaultRoomleConfig = {
  '100000': {
    "componentId": "vendeco:jalousie_master",
    "parameters": {
      "Width": "650",
      "Height": "909",
      "Hintergrund": "Ohne",
      "Optional_TopCover": "0",
      "Optional_LadderTape": "0",
      "Optional_SideTrack": "0",
      "Optional_EspeciallyDimming": "0",
      "BlindColor": "2380",
      "BlindColorTop": "120",
      "BlindWidth": "54",
      "MaterialType": "58",
      "InstallationPlace": "34",
      "FixationKind": "104",
      "HandlingSide": "45",
      "HandlingType": "67"
    },
  },
  '200000': {
    "componentId": "vendeco_draft0:Plissee_Test_mat02",
    "parameters": {
      "Width": "650",
      "WidthTop": "300",
      "WidthBottom": "650",
      "Height": "909",
      "HeightLeft": "700",
      "HeightRight": "909",
      "Hintergrund": "Ohne",
      "Optional_ComfortTrack": "0",
      "Optional_Stick": "0",
      "Optional_SideTrack": "0",
      "Optional_Wedges": "0",
      "Optional_Designhandle": "0",
      "PlisseeColor": "vendeco_draft0:6426",
      "PlisseeColorTop": "115",
      "InstallationPlace": "34",
      "Shape": "2",
      "PlisseeModel": "15",
      "MaterialType": "3",
    }
  },
  '300000': {
    "componentId": "vendeco:rollo_master",
    "parameters": {
      "Width": "650",
      "Height": "909",
      "Optional_OpenCassette": "0",
      "Optional_MountingProfile": "0",
      "Optional_SideTrack": "0",
      "Optional_TissueWrapped": "0",
      "Optional_Track": "0",
      "Optional_ComfortTrack": "0",
      "Optional_DesignBracket": "0",
      "Optional_SquareCassette": "0",
      "OptionalSquareCassetteOpenTop": "0",
      "Optional_ChildProof": "1",
      "Optional_ChainWeight": "0",
      "Optional_RotateRolloColor": "0",
      "InstallationPlace": "6318",
      "RolloColor": "40002",
      "RolloColor_UI": "vendeco:40002",
      "RolloColorTop": "125",
      "RolloModel": "763",
      "MaterialType": "70",
      "HandlingType": "76",
      "Hintergrund": "Mit",
      "BottomRail": "7031",
    }
  },
  '400000': {
    "componentId": "vendeco:lamellenvorhang",
    "parameters": {
      "Width": "650",
      "Height": "909",
      "HeightLeft": "2000",
      "HeightRight": "2500",
      "Hintergrund": "Ohne",
      "Optional_ChildProof": "0",
      "LamellaColor": "71808",
      "LamellaColor_UI": "8450",
      "LamellaColorTop": "127",
      "InstallationPlace": "93",
      "Shape": "86",
      "model": "6804"
    }
  },
  '500000': {
    "componentId": "vendeco:insektenschutz_master",
    "parameters": {
      "Width": "650",
      "Height": "909",
      "Hintergrund": "Ohne",
      "Optional_BarrierFree": "0",
      "InstallationPlace": "36",
      "InsectFrameColor": "2912",
      "InsectProductSelection": "98",
    }
  }
}


