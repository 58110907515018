export const attributes = {
  "100000": {
    MaterialType: [
      {id:58, name:"Aluminium"},
      {id:59, name:"Holz"},
    ],
    BlindWidth: [
      {id:55, name:"16mm"},
      {id:54, name:"25mm"},
      {id:56, name:"50mm"},
    ],
    InstallationPlace: [
      {id:34, name:"Glasfalz"},
      {id:35, name:"Fensterflügel"},
      {id:37, name:"Wand"},
      {id:93, name:"Decke/Nische"},
    ]
  },
  "200000": {
    // MaterialType: [
    //   {id:3, name:"Plissee"},
    //   {id:4, name:"Wabenplissee"},
    // ],
    Shape: [
      {id: 2, name: "Rechteck Tür Fenster"},
      {id: 11, name: "Dachfenster"},
      {id: 12, name: "Glasdach"},
      {id: 13, name: "Sonderformen"},
    ],
    PlisseeModel: [
      {id: 15, name: 'VS2', shape: 2},
      {id: 14, name: 'VS1', shape: 2},
      {id: 18, name: 'VS3', shape: 2},
      {id: 19, name: 'VS3SD', shape: 2},
      {id: 16, name: 'F1', shape: 2},
      {id: 17, name: 'F2', shape: 2},
      {id: 101, name: 'F4', shape: 2},
      {id: 20, name: 'DF10', shape: 11},
      {id: 21, name: 'DF20', shape: 11},
      {id: 22, name: 'DF30', shape: 11},
      {id: 25, name: 'VS4 Slope', shape: 13},
      {id: 26, name: 'VS5', shape: 13},
      {id: 27, name: 'VS9D', shape: 13},
      {id: 28, name: 'VS10D', shape: 13},
      {id: 23, name: 'F Slope 2', shape: 13},
      {id: 24, name: 'FD Slope 3', shape: 13},
      {id: 29, name: 'PL11', shape: 12},
    ],
    InstallationPlace: [
      {id:34, name:"Glasfalz"},
      {id:35, name:"Fensterflügel"},
      {id:36, name:"Fensternische"},
      {id:37, name:"Wand"},
    ]
  },
  "300000": {
    // MaterialType: [
    //   {id:70, name:"Rollo"},
    //   {id:71, name:"Doppelrollo"},
    // ],
    InstallationPlace: [
      {id:34, name:"Glasfalz"},
      {id:35, name:"Fensterflügel"},
      {id:37, name:"Wand"},
      {id:93, name:"Decke/Nische"},
      {id:6318, name:"Klemmträger"},
      {id:759, name:"Dachfenster"},
    ]
  },
  "400000": {
    BlindWidth: [
      {id:757, name:"127mm"},
      {id:758, name:"89mm"},
    ],
    Shape: [
      {id:86, name:"Rechteck"},
      {id:87, name:"Schräg links"},
      {id:88, name:"Schräg rechts"},
    ]
  },
  "500000": {
    InsectProductSelection: [
      {id:98, name:"Rahmen"},
      {id:99, name:"Rollo"},
      {id:100, name:"Plisseetür"},
    ],
    Optional_BarrierFree: [
      {id:0, name:"nicht barrierefrei"},
      {id:1, name:"barrierefrei"},
    ],
    InstallationPlace: [
      {id:36, name:"Fensternische"},
      {id:783, name:"Türnische"},
    ]
  }

}
