export const LadderTapeIdMap = {
    "6355": "781",
    "8513": "782",
    "8514": "772",
    "8515": "779",
    "8516": "778",
    "8517": "769",
    "8518": "774",
    "8519": "777"
}
