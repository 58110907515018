export const defaultSettings = {
  yaw: 0,
  pitch: 0,
  distance: 2.5,
  targetX: 0,
  targetY: 1.35,
  targetZ: 0,
  width: 612,
  height: 697,

}

export const zoomOffsets = {
  "100000": {},
  "200000": {},
  "300000": {},
  "400000": {},
  "500000": {}
}
