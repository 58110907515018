import { createApp } from 'vue'
import App from './App.vue'

// window.addEventListener('message', async function (message) {
//   console.log("[roomleframe] message received: ", message.data);
// });

const vue = createApp(App).mount('#app')

window.addEventListener('beforeunload', () => {
  vue.$destroy();
});
