export const LamellaIdMap = {
  "2271":"127110",
  "2272":"127140",
  "2273":"127200",
  "2274":"127130",
  "2275":"127120",
  "2276":"127220",
  "2277":"127230",
  "2278":"127180",
  "2279":"127240",
  "2280":"127210",
  "2281":"127190",
  "2282":"127150",
  "2283":"129312",
  "2284":"129322",
  "2285":"129382",
  "2286":"129352",
  "2287":"129362",
  "2288":"129372",
  "2289":"101141",
  "2290":"101001",
  "2291":"101151",
  "2292":"101041",
  "2293":"101111",
  "2294":"101131",
  "2295":"101181",
  "2296":"101051",
  "2297":"101101",
  "2298":"101071",
  "2299":"111512",
  "2300":"111502",
  "2301":"111572",
  "2302":"111532",
  "2303":"111552",
  "2304":"111542",
  "2305":"111562",
  "2306":"111522",
  "2307":"143001",
  "2308":"143101",
  "2309":"143401",
  "2310":"143701",
  "2311":"143301",
  "2312":"143201",
  "2313":"143601",
  "2314":"127012",
  "2315":"127042",
  "2316":"127022",
  "2317":"127032",
  "2318":"188212",
  "2319":"188252",
  "2320":"188722",
  "2321":"189002",
  "2322":"181003",
  "2323":"111004",
  "2324":"151104",
  "2325":"196104",
  "2326":"103252",
  "2327":"102002",
  "2328":"141132",
  "2329":"130492",
  "2330":"141142",
  "2331":"190012",
  "2332":"171003",
  "2333":"172253",
  "2334":"179303",
  "2335":"179603",
  "2336":"175533",
  "2337":"176253",
  "2338":"182102",
  "2339":"182112",
  "2340":"182132",
  "2341":"182152",
  "2342":"182142",
  "2343":"182182",
  "2344":"182162",
  "2345":"182172",
  "2346":"182122",
  "2347":"182192",
  "2348":"164202",
  "2349":"164232",
  "2350":"164212",
  "2351":"164242",
  "2352":"108571",
  "2353":"108531",
  "2354":"108511",
  "2355":"108501",
  "2356":"108521",
  "2357":"108541",
  "2358":"108551",
  "2359":"108561",
  "2360":"107094",
  "2361":"107114",
  "2362":"107234",
  "2363":"107134",
  "2364":"107074",
  "2365":"107144",
  "2366":"107244",
  "2367":"107034",
  "2368":"166104",
  "2369":"150104",
  "2370":"150204",
  "2371":"150304",
  "2372":"166604",
  "2373":"166624",
  "2374":"166614",
  "2375":"166634",
  "2376":"166644",
  "2377":"166654",
  "2378":"102114",
  "2379":"102124",
  "2419":"1003",
  "2420":"1013",
  "2421":"1014",
  "2422":"1023",
  "2423":"1024",
  "2424":"1033",
  "2425":"1034",
  "2426":"1043",
  "2427":"1044",
  "2428":"1053",
  "2429":"1063",
  "2430":"1161",
  "2431":"1171",
  "2432":"1191",
  "2433":"1211",
  "2434":"1283",
  "2435":"1911",
  "2436":"1921",
  "2437":"1931",
  "2438":"1951",
  "2439":"1961",
  "2440":"1971",
  "2441":"1981",
  "2442":"3404",
  "2443":"3414",
  "2444":"3424",
  "2445":"3434",
  "2446":"10032",
  "2447":"10042",
  "2448":"10052",
  "2449":"10132",
  "2450":"10142",
  "2451":"10152",
  "2452":"10434",
  "2453":"10444",
  "2454":"10454",
  "2455":"11102",
  "2456":"14002",
  "2457":"14012",
  "2458":"14022",
  "2459":"14032",
  "2460":"14102",
  "2461":"14202",
  "2462":"14302",
  "2463":"14402",
  "2464":"14502",
  "2465":"14602",
  "2466":"14702",
  "2467":"14802",
  "2468":"14902",
  "2469":"21263",
  "2470":"22902",
  "2471":"23002",
  "2472":"23102",
  "2473":"23202",
  "2474":"23302",
  "2475":"23314",
  "2476":"23324",
  "2477":"23334",
  "2478":"23344",
  "2479":"23354",
  "2480":"23364",
  "2481":"23374",
  "2482":"23384",
  "2483":"23394",
  "2484":"23402",
  "2485":"23414",
  "2486":"23424",
  "2487":"23434",
  "2488":"23444",
  "2489":"23454",
  "2490":"24014",
  "2491":"24024",
  "2492":"24044",
  "2493":"26104",
  "2494":"26404",
  "2495":"26414",
  "2496":"26424",
  "2497":"26604",
  "2498":"26714",
  "2499":"42213",
  "2500":"42223",
  "2501":"42243",
  "2502":"42252",
  "2503":"42264",
  "2504":"42274",
  "2505":"42283",
  "2506":"42294",
  "2507":"42313",
  "2508":"42323",
  "2509":"42433",
  "2510":"43001",
  "2511":"43112",
  "2512":"43201",
  "2513":"43212",
  "2514":"43301",
  "2515":"43312",
  "2516":"43401",
  "2517":"43412",
  "2518":"43512",
  "2519":"43601",
  "2520":"43612",
  "2521":"50004",
  "2522":"55104",
  "2523":"55204",
  "2524":"57011",
  "2525":"57211",
  "2526":"57301",
  "2527":"57411",
  "2528":"57711",
  "2529":"60102",
  "2530":"60112",
  "2531":"60502",
  "2532":"60602",
  "2533":"60702",
  "2534":"60802",
  "2535":"63973",
  "2536":"63983",
  "2537":"63993",
  "2538":"64063",
  "2539":"64093",
  "2540":"64100",
  "2541":"64111",
  "2542":"64113",
  "2543":"64121",
  "2544":"64161",
  "2545":"64163",
  "2546":"64200",
  "2547":"64202",
  "2548":"64212",
  "2549":"64232",
  "2550":"64242",
  "2551":"64300",
  "2552":"64400",
  "2553":"64500",
  "2554":"64700",
  "2555":"64900",
  "2556":"65000",
  "2557":"65100",
  "2558":"65200",
  "2559":"65300",
  "2560":"65400",
  "2561":"65500",
  "2562":"68001",
  "2563":"68201",
  "2564":"68401",
  "2565":"69103",
  "2566":"69403",
  "2567":"85200",
  "2568":"85900",
  "2569":"89402",
  "2570":"89622",
  "2571":"90003",
  "2572":"90103",
  "2573":"90203",
  "2574":"90303",
  "2575":"90403",
  "2576":"90503",
  "8277":"70000",
  "8278":"70001",
  "8279":"70002",
  "8280":"70003",
  "8281":"70004",
  "8282":"70005",
  "8283":"70006",
  "8284":"70007",
  "8285":"70008",
  "8286":"70009",
  "8287":"70010",
  "8288":"70011",
  "8289":"70100",
  "8290":"70101",
  "8291":"70102",
  "8292":"70103",
  "8293":"70104",
  "8294":"70105",
  "8295":"70106",
  "8296":"70200",
  "8297":"70201",
  "8298":"70202",
  "8299":"70203",
  "8300":"70204",
  "8301":"70205",
  "8302":"70206",
  "8303":"70207",
  "8304":"70208",
  "8305":"70300",
  "8306":"70301",
  "8307":"70302",
  "8308":"70303",
  "8309":"70304",
  "8310":"70305",
  "8311":"70400",
  "8312":"70401",
  "8313":"70402",
  "8314":"70403",
  "8315":"70404",
  "8316":"70405",
  "8317":"70406",
  "8318":"70407",
  "8319":"70408",
  "8320":"70409",
  "8321":"70410",
  "8322":"70411",
  "8323":"70412",
  "8324":"70413",
  "8325":"70414",
  "8326":"70415",
  "8327":"70416",
  "8328":"70417",
  "8329":"70418",
  "8330":"70419",
  "8331":"70420",
  "8332":"70421",
  "8333":"70422",
  "8334":"70423",
  "8335":"70424",
  "8336":"70425",
  "8337":"70426",
  "8338":"70427",
  "8339":"70428",
  "8340":"70500",
  "8341":"70501",
  "8342":"70502",
  "8343":"70503",
  "8344":"70504",
  "8345":"70505",
  "8346":"70600",
  "8347":"70601",
  "8348":"70602",
  "8349":"70603",
  "8350":"70604",
  "8351":"70605",
  "8352":"70606",
  "8353":"70607",
  "8354":"70609",
  "8355":"70610",
  "8356":"70701",
  "8357":"70800",
  "8358":"70900",
  "8359":"70901",
  "8360":"70902",
  "8361":"70903",
  "8362":"70904",
  "8363":"70907",
  "8364":"71000",
  "8365":"71001",
  "8366":"71002",
  "8367":"71003",
  "8368":"71004",
  "8369":"71005",
  "8370":"71006",
  "8371":"71007",
  "8372":"71008",
  "8373":"71009",
  "8374":"71010",
  "8375":"71011",
  "8376":"71012",
  "8377":"71013",
  "8378":"71014",
  "8379":"71015",
  "8380":"71016",
  "8381":"71017",
  "8382":"71018",
  "8383":"71019",
  "8384":"71020",
  "8385":"71021",
  "8386":"71022",
  "8387":"71023",
  "8388":"71100",
  "8389":"71101",
  "8390":"71102",
  "8391":"71103",
  "8392":"71104",
  "8393":"71105",
  "8394":"71200",
  "8395":"71201",
  "8396":"71202",
  "8397":"71203",
  "8398":"71204",
  "8399":"71205",
  "8400":"71206",
  "8401":"71300",
  "8402":"71301",
  "8403":"71302",
  "8404":"71303",
  "8405":"71304",
  "8406":"71400",
  "8407":"71401",
  "8408":"71402",
  "8409":"71403",
  "8410":"71404",
  "8411":"71405",
  "8412":"71406",
  "8413":"71407",
  "8414":"71408",
  "8415":"71409",
  "8416":"71410",
  "8417":"71414",
  "8418":"71415",
  "8419":"71416",
  "8420":"71418",
  "8421":"71420",
  "8422":"71600",
  "8423":"71601",
  "8424":"71602",
  "8425":"71603",
  "8426":"71604",
  "8427":"71605",
  "8428":"71606",
  "8429":"71607",
  "8430":"71608",
  "8431":"71609",
  "8432":"71610",
  "8433":"71611",
  "8434":"71612",
  "8435":"71613",
  "8436":"71614",
  "8437":"71615",
  "8438":"71616",
  "8439":"71617",
  "8440":"71700",
  "8441":"71701",
  "8442":"71800",
  "8443":"71801",
  "8444":"71802",
  "8445":"71803",
  "8446":"71804",
  "8447":"71805",
  "8448":"71806",
  "8449":"71807",
  "8450":"71808",
  "8451":"71809",
  "8452":"71810",
  "8453":"71811",
  "8454":"71900",
  "8455":"71901",
  "8456":"71902",
  "8457":"71903",
  "8458":"71904",
  "8459":"71905",
  "8460":"71906",
  "8461":"71907",
  "8462":"71908",
  "8463":"72000",
  "8464":"72001",
  "8465":"72002",
  "8466":"72003",
  "8467":"72004",
  "8468":"72005",
  "8469":"72006",
  "8470":"72007",
  "8471":"72008",
  "8472":"72009",
  "8473":"72010",
  "8474":"72011",
  "8475":"72100",
  "8476":"72101",
  "8477":"72102",
  "8478":"72103",
  "8479":"72104",
  "8480":"72105",
  "8481":"72200",
  "8482":"72201",
  "8483":"72202",
  "8484":"72203",
  "8485":"72204",
  "8486":"72205",
  "8487":"72400",
  "8488":"72401",
  "8489":"72402",
  "8490":"72500",
  "8491":"72501",
  "8492":"72502",
  "8493":"72503",
  "8494":"72504",
  "8495":"72505",
  "8496":"72507",
  "8497":"72508"

}
