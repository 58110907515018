export const PlisseeIdMap = {
    "8895":"707300",
    "8896":"707310",
    "8897":"707320",
    "8898":"707330",
    "8899":"707340",
    "8900":"707350",
    "8901":"707360",
    "8902":"707370",
    "8903":"707380",
    "8904":"707400",
    "8905":"707410",
    "8906":"707420",
    "8907":"707430",
    "8908":"707440",
    "8909":"707450",
    "8910":"707460",
    "8911":"707470",
    "8912":"707480",
    "8913":"707500",
    "8914":"707510",
    "8915":"707520",
    "8916":"707530",
    "8917":"707540",
    "8918":"707550",
    "8919":"707560",
    "8920":"707570",
    "8921":"707580",
    "8922":"707600",
    "8923":"707610",
    "8924":"707620",
    "8925":"707630",
    "8926":"707640",
    "8927":"707650",
    "8928":"707660",
    "8929":"707670",
    "8930":"707680"

}
