<template>
  <div class="container">
    <div class="scene-viewer" :style="{ width: sceneStyle.width+'px !important', height: sceneStyle.height+'px !important', }">
      <iframe id="roomleframe" ref="roomleframe" class="scene-frame" :src="frameUrl" ></iframe>
      <!--<Scene class="sceneview"  />-->
      <div class="sister-one"></div>
      <div class="sister-two"></div>
      <div class="sister-three"></div>
      <div class="sister-four"></div>
      <div class="sister-five"></div>
      <div class="sister-six"></div>
      <div class="sister-seven"></div>
      <div class="sister-eight"></div>
      <div class="sister-nine"></div>
      <div class="sister-ten"></div>
      <div class="sister-one"></div>
      <div class="sister-one"></div>
      <div class="sister-one"></div>
      <div class="sister-one"></div>
      <div class="sister-one"></div>
      <div class="sister-one"></div>
      <div class="scenebuttons">
        <div class="btns">
         <!-- <button @click="start()">start</button><span style="display:inline-block;width:15px"></span>-->
          <button @click="reset()">reset</button>
          <button @click="getRoomleImage()">image</button>
        </div>
      </div>
    </div>
    <div class="controls-container">
      <div class="controls top" v-if="1===2">
        <div class="ctrl" v-for="(item, key) in ['Width','Height']" :key="key">
          <label :for="key">product {{ item }}:</label>
          <input
              :id="key"
              type="number"
              :min="0"
              :max="5000"
              :step="1"
              v-model="roomleConfig[articleNumber].parameters[item]"/>
        </div>
<!--        <div class="framectrl">-->
<!--          <div class="ctrl" v-for="(item, key) in sceneStyle" :key="key">-->
<!--            <label :for="key">frame {{ key }}:</label>-->
<!--            <input-->
<!--                :id="key"-->
<!--                type="number"-->
<!--                :min="0"-->
<!--                :max="5000"-->
<!--                :step="1"-->
<!--                v-model="sceneStyle[key]"/>-->
<!--          </div>-->
          <div class="btns">
            <button @click="() => { sceneStyle.width=612;sceneStyle.height=697; }">612x697</button><span style="display:inline-block;width:5px"></span>
            <button @click="() => { sceneStyle.width=390;sceneStyle.height=342; }">312x342</button><span style="display:inline-block;width:5px"></span>
          </div>
        </div>
      </div>
      <div class="controls bottom">
        <div class="ctrl" v-for="(item, key) in cameraSettings" :key="key">
          <label :for="key">{{ key }}:</label>
          <input
              class="slider"
              :id="key"
              type="range"
              :min="ctrlValues2.min[key]"
              :max="ctrlValues2.max[key]"
              :step="ctrlValues2.step[key]"
              v-model="cameraSettings[key]"/>
          <input
              :id="key"
              type="number"
              :min="ctrlValues2.min[key]"
              :max="ctrlValues2.max[key]"
              :step="ctrlValues2.step[key]"
              v-model="cameraSettings[key]"/>
        </div>
        <div class="output">
          <textarea id="valuestring" type="text" :value="JSON.stringify(cameraSettings)" disabled></textarea><br>
        </div>
        <div class="controls params">
          <div class="ctrl">
            <label :for="articleNumber">Produkt:</label>
            <select id="articleNumber" v-model="articleNumber">
              <option value="100000">Jalousie</option>
              <option value="200000">Plissee</option>
              <option value="300000">Rollo</option>
              <option value="400000">Lamellenvorhang</option>
              <option value="500000">Insektenschutz</option>
            </select>
          </div>
          <template v-for="(item, key) in roomleConfig[articleNumber].parameters" :key="key">
            <template v-if="key==='Hintergrund'">
              <div class="ctrl" >
                <label :for="key">Wand:</label>
                <select id="rmlBackgroundSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <option value="Mit">Mit</option>
                  <option value="Ohne">Ohne</option>
                </select>
              </div>
            </template>
            <template v-else-if="key==='MaterialType' && availableAttributes[articleNumber].MaterialType">
              <div class="ctrl" >
                <label :for="key">Material:</label>
                <select id="rmlMaterialSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <template v-for="mat in availableAttributes[articleNumber].MaterialType" :key="mat.id">
                    <option :value="mat.id.toString()" >{{ mat.name }}</option>
                  </template>
                </select>
              </div>
            </template>
            <template v-else-if="key==='BlindWidth' && availableAttributes[articleNumber].BlindWidth">
              <div class="ctrl" >
                <label :for="key">Lamellen:</label>
                <select id="rmlBlindWidthSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <template v-for="mat in availableAttributes[articleNumber].BlindWidth" :key="mat.id">
                    <option :value="mat.id.toString()" >{{ mat.name }}</option>
                  </template>
                </select>
              </div>
            </template>
              <template v-else-if="key==='PlisseeModel' && availableAttributes[articleNumber].PlisseeModel">
                <div class="ctrl" >
                  <label :for="key">Modell:</label>
                  <select id="rmlModelSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                    <template v-for="model in availableAttributes[articleNumber].PlisseeModel" :key="model.id">
                      <option  v-if="model.shape.toString()===roomleConfig[articleNumber].parameters['Shape']" :value="model.id.toString()" >{{ model.name }}</option>
                    </template>
                  </select>
                </div>
              </template>
              <template v-else-if="key==='Shape' && availableAttributes[articleNumber].Shape">
                <div class="ctrl" >
                  <label :for="key">Form:</label>
                  <select id="rmlShapeSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                    <template v-for="model in availableAttributes[articleNumber].Shape" :key="model.id">
                      <option :value="model.id.toString()" >{{ model.name }}</option>
                    </template>
                  </select>
                </div>
              </template>
            <template v-else-if="key==='InstallationPlace' && availableAttributes[articleNumber].InstallationPlace">
              <div class="ctrl" >
                <label :for="key">Montage:</label>
                <select id="rmlInstallationSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <template v-for="model in availableAttributes[articleNumber].InstallationPlace" :key="model.id">
                    <option :value="model.id.toString()" >{{ model.name }}</option>
                  </template>
                </select>
              </div>
            </template>
            <template v-else-if="key==='InsectProductSelection' && availableAttributes[articleNumber]">
              <div class="ctrl" >
                <label :for="key">Produkt:</label>
                <select id="rmlInsectProductSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <template v-for="model in availableAttributes[articleNumber].InsectProductSelection" :key="model.id">
                    <option :value="model.id.toString()" >{{ model.name }}</option>
                  </template>
                </select>
              </div>
            </template>
            <template v-else-if="key==='Optional_BarrierFree'">
              <div class="ctrl" >
                <label :for="key">Barrierefrei:</label>
                <select id="rmlBarrierFreeSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <option value="0">Nein</option>
                  <option value="1">Ja</option>
                </select>
              </div>
            </template>
            <template v-else-if="key==='PlisseeColor'">
              <div class="ctrl" >
                <label :for="key">PlisseeColor:</label>
                <select id="rmlPlisseeColorSelect" v-model="roomleConfig[articleNumber].parameters[key]">
                  <option value="6426">Sommergelb 133050</option>
                  <option value="6358">Grün-Blau Aquarell 138020</option>
                  <option value="6359">Grau Aquarell 138030</option>
                  <option value="6360">Blau Aquarell 139010</option>
                  <option value="6361">Grau Aquarell 139020</option>
                </select>
              </div>
            </template>
            <template v-else-if="key in measureAttributes">
              <input
                  :id="key"
                  type="number"
                  min="0"
                  max="9999"
                  v-model="roomleConfig[articleNumber].parameters[key]"/>
            </template>
          </template>

        </div>
        <div class="output">
          <textarea id="paramstring" type="text" :value="JSON.stringify(roomleConfig[articleNumber])" disabled></textarea><br>
        </div>
      </div>
    </div>


<!--  <div style="display:none;position:fixed;bottom:0;left:0;background:darkgray;width:200px;height:400px;padding:10px;">-->

</template>

<script>
import { onMounted, ref, reactive, watch } from "vue";
import Scene from "@/components/Scene";
import { defaultRoomleConfig } from "@/config/roomle";
import { defaultSettings } from "@/config/camera";
import { ctrlValues } from "@/config/maker";
import { attributes } from "@/config/misc";

export default {
  name: "SceneMaker",
  components: {

  },
  setup() {
    const frameBaseUrl = process.env.NODE_ENV==='production' ? 'https://roomle.configurator.vendeco.com' : 'http://192.168.2.4:8080';
    const frameUrl = frameBaseUrl + '/'

    const roomleframe = ref(null);

    let started = false;

    const ctrlValues2 = {...ctrlValues};

    const measureAttributes = [
      "Height",
      "Height1",
      "Height2",
      "HeightLeft",
      "HeightRight",
      "Width" ,
      "Width1" ,
      "Width2" ,
      "WidthTop" ,
      "WidthBottom",
      "Depth",
    ];

    const availableAttributes = {...attributes};

    let articleNumber = ref("300000");

    let sceneStyle = reactive({
      width: defaultSettings.width,
      height: defaultSettings.height,
    });

    let cameraSettings = reactive(
        {
          yaw: defaultSettings.yaw,
          pitch: defaultSettings.pitch,
          distance: defaultSettings.distance,
          targetX: defaultSettings.targetX,
          targetY: defaultSettings.targetY,
          targetZ: defaultSettings.targetZ,
        }
    );

    let roomleConfig = reactive({...defaultRoomleConfig });

    const customConfig = reactive({});

    let workerQueue = [];
    const workerQueueMaxLength = 25;
    let processQueueTimeout;

    onMounted(async () => {
      window.addEventListener('message',async function(message) {
        //console.log('msg1 rec',message);
        if(message.data.type==='focusCamera') {
          cameraSettings.yaw = message.data.data.yaw
          cameraSettings.pitch = message.data.data.pitch
          cameraSettings.distance = message.data.data.distance
          cameraSettings.targetX = message.data.data.targetX
          cameraSettings.targetY = message.data.data.targetY
          cameraSettings.targetZ = message.data.data.targetZ
        }

        if(message.data.type==='roomle' && message.data.data==='frameLoaded') {
          start();
        }
      });
    });


    // const reset = () => {
    //   cameraSettings.yaw = defaultSettings.yaw;
    //   cameraSettings.pitch = defaultSettings.pitch;
    //   cameraSettings.distance =  defaultSettings.distance;
    //   cameraSettings.targetX = defaultSettings.targetX ;
    //   cameraSettings.targetY = defaultSettings.targetY;
    //   cameraSettings.targetZ = defaultSettings.targetZ;
    //   // sceneStyle.width = defaultSettings.width;
    //   // sceneStyle.height = defaultSettings.height;
    //   applyValues(cameraSettings,true);
    // }
    //
    // const testHandle = () => {
    //
    //   const i = RoomleConfigurator.getScene().children[2].children.findIndex( (item) => item.userData.geometryId === "vendeco:Plisee_Bediengriff");
    //
    //   if(i!==-1) {
    //     const box = new THREE.Box3().setFromObject(RoomleConfigurator.getScene().children[mainChild].children[i])
    //     const center = box.getCenter(new THREE.Vector3());
    //     const size = box.getSize(new THREE.Vector3());
    //
    //     const maxDim = Math.max( size.x, size.y, size.z );
    //     const fov = fovValue * ( Math.PI / 180 );
    //     let cameraZ = Math.abs( maxDim / 2 * Math.tan( fov * 2 ) ); //Applied fifonik correction
    //
    //     const realOffset = roomleConfig[articleNumber.value].parameters.Hintergrund==='Mit' ? offset : 1;
    //
    //     cameraSettings.targetX = center.x;
    //     cameraSettings.targetY = center.y;
    //     cameraSettings.targetZ = center.z;
    //     cameraSettings.distance = (cameraZ/2)*realOffset;
    //     applyValues(cameraSettings, true);
    //   }
    // }
    //
    // const testProduct = () => {
    //
    //   const i = RoomleConfigurator.getScene().children[2].children.findIndex( (item) => item.userData.materialId && item.userData.materialId=== "vendeco:"+roomleConfig[articleNumber.value].parameters.PlisseeColor);
    //
    //   if(i!==-1) {
    //     const box = new THREE.Box3().setFromObject(RoomleConfigurator.getScene().children[mainChild].children[i])
    //     const center = box.getCenter(new THREE.Vector3());
    //     const size = box.getSize(new THREE.Vector3());
    //
    //     const maxDim = Math.max( size.x, size.y, size.z );
    //     const fov = fovValue * ( Math.PI / 180 );
    //     let cameraZ = Math.abs( maxDim / 2 * Math.tan( fov * 2 ) ); //Applied fifonik correction
    //
    //     const realOffset = roomleConfig[articleNumber.value].parameters.Hintergrund==='Mit' ? offset : 1;
    //
    //     cameraSettings.targetX = center.x;
    //     cameraSettings.targetY = center.y;
    //     cameraSettings.targetZ = center.z;
    //     cameraSettings.distance = (1+cameraZ)*realOffset;
    //     applyValues(cameraSettings, true);
    //   }
    // }
    //
    // const copyValues = () => {
    //   let copyText = document.querySelector("#valuestring");
    //   copyText.select();
    //   document.execCommand("copy");
    // }
    //
    // const configuratorListener = () => {
    //   window.addEventListener('message',(message) => {
    //     console.log(message);
    //   })
    // }

    const start = () => {
      if(!started) {
        document.getElementById('roomleframe').contentWindow.postMessage({type: 'initRoomle'});
        started = true;
        triggerProcessQueue();
      }
    }

    const reset = () => {
      sceneStyle.width = defaultSettings.width;
      sceneStyle.height = defaultSettings.height;


      cameraSettings.yaw = defaultSettings.yaw;
      cameraSettings.pitch = defaultSettings.pitch;
      cameraSettings.distance = defaultSettings.distance;
      cameraSettings.targetX = defaultSettings.targetX;
      cameraSettings.targetY = defaultSettings.targetY;
      cameraSettings.targetZ = defaultSettings.targetZ;
    }

    const addToWorkerQueue = (name, cb) => {
      if (workerQueue.length > workerQueueMaxLength) {
        //console.log('[r24] worker queue full - discarding last');
        workerQueue.pop();
      }
      //console.log('[r24] adding to worker queue');
        workerQueue.push({label: name, callBack: cb});
    }

    const triggerProcessQueue = () => {
      //console.log('[r24] triggering queue');
      if (processQueueTimeout) window.clearTimeout(processQueueTimeout);
      processQueueTimeout = window.setTimeout(processQueue, 10);
    }

    const processQueue = () => {
      if (started) {
        if(workerQueue.length>0) {
          let job = workerQueue.shift();
          if (job && job.callBack) {
            //console.log('[r24] executing queue callback');
            job.callBack();
          }
        }
        /* retrigger */
        window.setTimeout(function () {
          triggerProcessQueue()
        }, 500);
      }
    }

    watch(sceneStyle, (sceneStyle) => {
      //console.log("[r24] sceneStyle changed: ",sceneStyle);
      // setTimeout(() => { frame.contentWindow.dispatchEvent(new Event('resize')) }, 50);
    })

    watch(cameraSettings, (cameraSettings) => {
      //console.log("[r24] cameraSettings changed: ",cameraSettings);
      addToWorkerQueue('camera',() => {
        document.getElementById('roomleframe').contentWindow.postMessage({
          type: 'cameraSettings',
          data: {...cameraSettings}
        });
      });
    });

    watch(roomleConfig, (roomleConfig) => {
      // console.log("[r24] roomleConfig changed: ",roomleConfig);

      addToWorkerQueue('config',() => {
        document.getElementById('roomleframe').contentWindow.postMessage({
          type: 'attributeSelection',
          data: {...roomleConfig[articleNumber.value].parameters}
        });
        document.getElementById('roomleframe').contentWindow.postMessage({
          type: 'wallVisible',
          data: roomleConfig[articleNumber.value].parameters.Hintergrund === 'Mit'
        });
        document.getElementById('roomleframe').contentWindow.postMessage({
          type: 'cameraSettings', data: {...cameraSettings}
        });

        setTimeout(() => {
          roomleframe.value.click();
        }, 500);
      });
    })

    watch(articleNumber, (articlenumber) => {
      //console.log("[r24] articleNumber changed: ",roomleConfig);
      document.getElementById('roomleframe').contentWindow.postMessage( { type: 'setArticleNumber', data: articleNumber.value });
    })

    const getRoomleImage = () => {
      document.getElementById('roomleframe').contentWindow.postMessage( { type: 'makeScreenshot' });
    }

    return {
      frameUrl,
      roomleframe,
      reset,
      cameraSettings,
      sceneStyle,
      ctrlValues2,
      articleNumber,
      roomleConfig,

      availableAttributes,
      start,
      started,
      measureAttributes,
      getRoomleImage
    };
  }


};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin: 50px auto;
  display:block;
  height: 100%;
  width: 50%;
  text-align:center;
  position:relative;
}
.scene-viewer {
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  height: 600px;
  line-height: 16px;
  width: 684px;
  margin: 0 auto;
  position:relative;
}

.scene-frame {
  width: 100%;
  height: 100%;
  /*border:1px solid red;*/
  position:relative;
}

.sister-one:before {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px dashed black;
  transform: translateY(-50%);
  opacity: 0.5;
}

.sister-two:before {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  border-top: 1px dashed black;
  transform: translateY(-10%);
  opacity: 0.5;
}

.sister-two:after {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10%;
  border-left: 1px dashed black;
  transform: translateX(-10%);
  opacity: 0.5;
}

.sister-three:before {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  border-top: 1px dashed black;
  transform: translateY(-90%);
  opacity: 0.5;
}

.sister-three:after {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 90%;
  border-left: 1px dashed black;
  transform: translateX(-90%);
  opacity: 0.5;
}

.sister-one:after {
  z-index:999999999;
  content:"";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  border-left: 1px dashed black;
  transform: translateX(-50%);
  opacity: 0.5;
}

.controls-container {

  margin: 75px auto;

}

.controls {
  flex: 0 0 100%;

}

.ctrl {
  /*display:grid;*/
  /*grid-template-columns: 100px 300px 75px;*/
  /*grid-column-gap: 15px;*/
  width: 300px;
  /*text-align:left;*/
  /*margin-bottom: 25px;*/
  display:inline-block;
  margin: 5px
}

.ctrl > label {
  display: inline-block;
  width:100px;

}

.controls.params {
  text-align: left;

}

.controls.params > .ctrl  {
  /*grid-template-columns: 300px 175px;*/
  /*grid-column-gap: 35px;*/
}

.controls.params > .ctrl > label {

}

label {
  font-family: monospace;
  font-weight: 700;
}

input[type=number] {
  width: 75px
}

input[type=range] {
  width: 100px;
  margin-left: 10px;
  margin-right:10px;
}

select {
  width: 150px;
}

.output {
  margin-top: 20px;
}

#valuestring {
  width: 100%;
  height: 25px;
}

#paramstring {
  width: 100%;
  height: 75px;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #000;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #04AA6D;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #04AA6D;
  cursor: pointer;
}

.btns {
  height: 35px;
  flex: 0 0 100%;
  margin-top:10px;
  text-align:center;
}
</style>
